import React, { ChangeEvent, FocusEvent, useState } from 'react';
import styles from './password.module.scss';
import LockIcon from '../../icons/lock.icon';
import EyeIcon from '../../icons/eye.icon';
import HideEyeIcon from '../../icons/hideEye.icon';

const PasswordField = ({
  label,
  id,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  autoComplete,
  className,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [placeholdertext, setPlaceholderText] = useState(placeholder);

  const handleFocus = () => {
    setPlaceholderText(placeholder.substring(5));
  };

  const handleBlur = (event) => {
    if (event.target.value === '') {
      setPlaceholderText(placeholder);
    }
  };

  return (
    <div className="form-group">
      {label && <label htmlFor="">{label}</label>}
      <div
        className={`input-container d-flex align-items-center ${
          styles.input_icon_wrap
        }  ${isFocused ? 'focused' : ''} ${styles.input_group}`}
      >
        <div className={` ${styles.input_icon}`}>{<LockIcon />}</div>
        <input
          type={passwordVisible ? 'text' : 'password'}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur && onBlur(e);
            handleBlur(e);
          }}
          onFocus={() => {
            setIsFocused(true);
            handleFocus();
          }}
          placeholder=""
          className={`${styles.input_pass} ${className} primary-input`}
          style={{
            borderRight: passwordVisible
              ? isFocused
                ? '1px solid #146ef5'
                : '1px solid #dee3e7'
              : isFocused
              ? '1px solid #146ef5'
              : '1px solid #dee3e7',
          }}
          autoComplete="off"
        />
        <label class="input-placeholder">{placeholdertext}</label>
        {value && value.length > 0 && (
          <div
            className={`input-group-append ${
              isFocused ? styles.focused : ''
            } ${className}`}
          >
            <span
              className={styles.info}
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <HideEyeIcon /> : <EyeIcon />}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
