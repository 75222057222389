import { memo, useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import NoContentCard from "../../../atoms/NoContentCard";
import Table from "./Table";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import { Cookies, useCookies } from "react-cookie";
import { getData } from "../../../../services";
import { Pagination } from "@mui/material";
import TabStrip from "../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Assign from "../../../icons/Assign";

const PaymentManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);

  const [activeTab, setActiveTab] = useState("Review");
  const [statusData, setStatusData] = useState();

  const [searchData, setSearchData] = useState();

  const getPaymentStatus = async () => {
    const res = await getData({
      endpoint: "payment/getAllPaymentStatus",
      token: cookies.t,
    });
    if (res) {
      setStatusData(res.data);
    }
  };
  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "payment/getAllPaymentStatus",
        token: cookies.t,
      });
      if (res) {
        setStatusData(res.data);
      }
    })();
    getPaymentStatus();
  }, []);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Review",
        view: (
          <Table
            type="New"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
          />
        ),
        badge: statusData?.New || 0,
      },
      {
        id: 2,
        label: "Reserves",
        view: (
          <Table
            type="Reserves"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
          />
        ),
        badge: statusData?.Reserves || 0,
      },
      {
        id: 3,
        label: "Pending",
        view: (
          <Table
            type="Pending"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
          />
        ),
        badge: statusData?.Pending || 0,
      },
      {
        id: 4,
        label: "Assigned",
        view: <Table type="Assigned" getPaymentStatus={getPaymentStatus} />,
        badge: statusData?.Assigned || 0,
      },
    ],
    [statusData, searchData],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Payment</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <CustomButton
            text="Add Payment"
            type="btn-primary"
            handleClick={() => {
              navigate("/dashboard/payments/add");
            }}
          />
        </div>
      </div>

      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};
export default PaymentManagement;
