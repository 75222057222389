export const capitalizeLetter = (str) => {
  return str?.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const Obj2FormData = (obj, formData) => {
  if (!obj) return null;
  const createFormData = function (obj, subKeyStr = "") {
    for (const [key, value] of Object.entries(obj)) {
      const subKeyStrTrans = subKeyStr ? subKeyStr + "[" + key + "]" : key;
      if (value instanceof File) {
        const k = subKeyStr ? subKeyStr : key;
        formData.append(k, value, value.name);
        continue;
      }
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        if (value || typeof value === "boolean") {
          formData.append(subKeyStrTrans, value);
        }
      } else if (typeof value === "object" && value !== null) {
        createFormData(value, subKeyStrTrans);
      }
    }
  };
  createFormData(obj);
  return formData;
};
