import { Outlet } from 'react-router-dom';
import { dashboardRoute } from '../../../routes/routes';
import Navbar from '../../molecules/Navbar';
import Sidebar from '../../molecules/Sidebar';
import styles from './style.module.scss';
import { useState } from 'react';

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  return (
    <div className={styles.dashboard_container}>
      <Sidebar isActive={isActive} setIsActive={setIsActive} />
      <div className={styles.main_content_container}>
        {/* <Navbar /> */}
        <div
          className={styles.content}
          style={{ width: isActive ? `calc(100vw - 270px)` : 'auto' }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default DashboardLayout;
