import { useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import { getData } from "../../../../../services";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import style from "./index.module.scss";
import moment from "moment/moment";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import Close from "../../../../icons/Close";

const FundedView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [fundedDataById, setFundedDataById] = useState({});
  const [topBarData, setTopData] = useState("Basic Info");
  const [selectedImage, setSelectedImage] = useState([]);
  const [pricingData, setPricingData] = useState({});

  const handleMultipleImages = (file) => {
    setSelectedImage((prev) => [...prev, file]);
  };

  const handleRemoveImage = (imageToRemove) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove),
    );
  };

  const getFundedDataById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setFundedDataById(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getFundedDataById();
      getCounterPartyDetails();
    }
  }, [id, topBarData]);

  const getCounterPartyDetails = async () => {
    try {
      if (fundedDataById?.transaction_type === "Client") {
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById?.client_id,
          },
        });
        console.log("res", res?.data);
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
        }
      } else if (fundedDataById?.transaction_type === "Supplier") {
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById?.supplier_id,
          },
        });
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
        }
      } else if (fundedDataById?.transaction_type === "Obligor") {
        const res = await getData({
          endpoint: "couterParty/getCounterPartyById",
          token: cookies.t,
          params: {
            id: fundedDataById?.obligor_id,
          },
        });
        console.log("res", res?.data);
        if (res?.data) {
          const priceRes = await getData({
            endpoint: "price/getPriceById",
            token: cookies.t,
            params: { id: res?.data?.pricing_id },
          });
          setPricingData(priceRes?.data);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  console.log("fundedDataById", fundedDataById);
  console.log("topbar", topBarData);
  console.log("pricingData", pricingData);

  return (
    <>
      <Heading className="HeadingStyle">Fund View</Heading>
      <div className={style.buttonStyle}>
        <CustomButton
          text="Basic Info"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Basic Info");
          }}
        />
        <CustomButton
          text="Interest Summary"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Interest Summary");
          }}
        />
        <CustomButton
          text="Interest Details"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Interest Details");
          }}
        />
        {/* <CustomButton
          text="Internal Info"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Internal Info");
          }}
        /> */}
        <CustomButton
          text="Verification"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Verification");
          }}
        />
        <CustomButton
          text="Upload Additional Support"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Upload Additional Support");
          }}
        />
        <CustomButton
          text="Schedule"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Schedule");
          }}
        />
        {/* <CustomButton
          text="Intelligence"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Intelligence");
          }}
        /> */}
        <CustomButton
          text="Edit"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setTopData("Edit");
          }}
        />
      </div>
      <div className={style.bothsidewrap}>
        {topBarData === "Basic Info" && (
          <div className={style.fundedViewleftWrap}>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Asset Information</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={style.label}>Transaction Type</td>
                  <td className={style.value}>
                    {fundedDataById?.transaction_type}
                  </td>
                </tr>
                <tr>
                  <td className={style.label}>Client Invoice ID/Ref</td>
                  <td className={style.value}>
                    {fundedDataById?.invoice_number ||
                      fundedDataById?.referance}
                  </td>
                </tr>
                <tr>
                  <td className={style.label}>Client Name</td>
                  <td className={style.value}>{fundedDataById?.client_name}</td>
                </tr>
                {fundedDataById?.obligor_name && (
                  <tr>
                    <td className={style.label}>Obligor Name</td>
                    <td className={style.value}>
                      {fundedDataById?.obligor_name}
                    </td>
                  </tr>
                )}
                {fundedDataById?.supplier_name && (
                  <tr>
                    <td className={style.label}>Supplier Name</td>
                    <td className={style.value}>
                      {fundedDataById?.supplier_name}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={style.label}>Term</td>
                  <td className={style.value}>
                    {fundedDataById?.payment_terms}
                  </td>
                </tr>
                {fundedDataById?.req_advance_value && (
                  <tr>
                    <td className={style.label}>Requested Advance Value</td>
                    <td className={style.value}>
                      {fundedDataById?.req_advance_value}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={style.label}>Cost</td>
                  <td className={style.value}>--</td>
                </tr>
                <tr>
                  <td className={style.label}>Face Value</td>
                  <td className={style.value}>{fundedDataById?.face_value}</td>
                </tr>
                <tr>
                  <td className={style.label}>Currency</td>
                  <td className={style.value}>{fundedDataById?.currency}</td>
                </tr>
                <tr>
                  <td className={style.label}>Invoice Date</td>
                  <td className={style.value}>
                    {" "}
                    {moment(fundedDataById?.invoice_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
                {fundedDataById?.due_date && (
                  <tr>
                    <td className={style.label}>Due Date</td>
                    <td className={style.value}>
                      {moment(fundedDataById?.due_date).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={style.label}>Funding Date</td>
                  <td className={style.value}>
                    {moment(fundedDataById?.createdAt).format("YYYY-MM-DD")}
                  </td>
                </tr>
                <tr>
                  <td className={style.label}>Participant</td>
                  <td className={style.value}>--</td>
                </tr>
                <tr>
                  <td className={style.label}>Status</td>
                  <td className={style.value}>{fundedDataById?.status}</td>
                </tr>
                <tr>
                  <td className={style.label}>
                    Sechedule Status
                    {/* <CustomButton
                      text="Refresh"
                      type="btn-primary"
                      size="btn-lg"
                      handleClick={() => {}}
                    /> */}
                  </td>
                  <td className={style.value}>--</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {topBarData === "Interest Summary" && (
          <div className={style.fundedViewleftWrap}>
            <div>
              <Heading className="HeadingSubStyle">Payments</Heading>
              <label>Loading ...</label>
            </div>
            <div>
              <Heading className="HeadingSubStyle">Accrued Date</Heading>
              <label>As of Date</label>
              <div style={{ position: "relative", width: "100%" }}>
                <DateSelector
                  placeholder="Select Date"
                  onChange={(option) => {
                    console.log("option", option);
                  }}
                />
              </div>
            </div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Pricing Information</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={style.label}>Pricing ID</td>
                    <td className={style.value}>--</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Name</td>
                    <td className={style.value}>{pricingData?.name}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Description</td>
                    <td className={style.value}>{pricingData?.description}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Top Rate</td>
                    <td className={style.value}>{pricingData?.top_rate}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Days</td>
                    <td className={style.value}>{pricingData?.days}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Advance Rate</td>
                    <td className={style.value}>{pricingData?.advance_rate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {topBarData === "Upload Additional Support" && (
          <div className={style.fundedViewleftWrap}>
            <div>
              <Heading className="HeadingSubStyle">
                Attach Additional Support Files
              </Heading>
              <ImageSelector
                placeholder="Upload Supporting Documents"
                handleFile={(file) => {
                  handleMultipleImages(file);
                }}
                multiple
                // customStyles={{ width: "23.9%" }}
              />
              <div className={style.uploadImageWrap}>
                {selectedImage?.map((item) => {
                  return (
                    <div className={`col-3`}>
                      <div className={style.imagesNameStyle}>
                        <span className={style.imagesName}>{item?.name}</span>
                        <div
                          className={style.closeIcon}
                          onClick={() => {
                            handleRemoveImage(item);
                          }}
                        >
                          <Close />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <CustomButton
                text="Save"
                type="btn-primary"
                size="btn-lg"
                handleClick={() => {}}
              />
            </div>
          </div>
        )}
        {topBarData === "Interest Details" && (
          <div className={style.fundedViewleftWrap}>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Asset Information</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={style.label}>Term</td>
                    <td className={style.value}>
                      {fundedDataById?.payment_terms}
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Cost</td>
                    <td className={style.value}>--</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Face Value</td>
                    <td className={style.value}>
                      {fundedDataById?.face_value}
                    </td>
                  </tr>
                  <tr>
                    <td className={style.label}>Currency</td>
                    <td className={style.value}>{fundedDataById?.currency}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Invoice Date</td>
                    <td className={style.value}>
                      {" "}
                      {moment(fundedDataById?.invoice_date).format(
                        "YYYY-MM-DD",
                      )}
                    </td>
                  </tr>
                  {fundedDataById?.due_date && (
                    <tr>
                      <td className={style.label}>Due Date</td>
                      <td className={style.value}>
                        {moment(fundedDataById?.due_date).format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className={style.label}>Funding Date</td>
                    <td className={style.value}>
                      {moment(fundedDataById?.createdAt).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Pricing Information</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={style.label}>Pricing ID</td>
                    <td className={style.value}>--</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Name</td>
                    <td className={style.value}>{pricingData?.name}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Description</td>
                    <td className={style.value}>{pricingData?.description}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Top Rate</td>
                    <td className={style.value}>{pricingData?.top_rate}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Days</td>
                    <td className={style.value}>{pricingData?.days}</td>
                  </tr>
                  <tr>
                    <td className={style.label}>Advance Rate</td>
                    <td className={style.value}>{pricingData?.advance_rate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {(topBarData === "Upload Additional Support" ||
          topBarData === "Interest Details") && (
          <div className={style.fundedViewRightWrap}>
            <Heading className="HeadingSubStyle">
              Accrued Interest Table
            </Heading>
            <div className={style.DateRangeWrap}>
              <div style={{ position: "relative", width: "40%" }}>
                <DateSelector
                  placeholder="Start Date"
                  onChange={(option) => {
                    console.log("option", option);
                  }}
                />
              </div>
              <div style={{ position: "relative", width: "40%" }}>
                <DateSelector
                  placeholder="End Date"
                  onChange={(option) => {
                    console.log("option", option);
                  }}
                />
              </div>
            </div>
            <CustomButton
              text="Run"
              type="btn-primary"
              size="btn-lg"
              handleClick={() => {}}
            />
          </div>
        )}
        {(topBarData === "Basic Info" || topBarData === "Interest Summary") && (
          <div className={style.fundedViewRightWrap}>
            <div className={style.iframeStyle}>
              <iframe
                width="100%"
                height="100%"
                src={fundedDataById?.documents?.[0]}
                title="fundedDocument"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default FundedView;
