import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../services";
import { statusData } from "../../../../../helpers/staticData";
import SelectDropdown from "../../../../atoms/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import CounterDropDown from "../../../../molecules/CounterDataDropdown";
import TagDropdown from "../../../../atoms/MyDropdown/TagDropdown";

const UserForm = ({ onClose, initialValues, id, refetchData, setEditData }) => {
  const [cookies] = useCookies(["t"]);
  const [clientData, setClientData] = useState(false);

  const initialData = {
    name: initialValues?.name || "",
    client_id: initialValues?.client_id || "",
    client_name: initialValues?.client_name || "",
  };

  const getRolesData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/couterParty/getAllCountersList",
        params: {
          counterparty_type: ["Client", "Supplier", "Obligor", "Pre-Client"],
        },
        token: cookies.t,
      });

      if (res) {
        setClientData(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getRolesData();
  }, [getRolesData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    client_id: Yup.string().required("Client ID is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    console.log("entered 1");

    const res = await postData({
      endpoint: "rolegroup/create",
      token: cookies.t,
      data: { ...values },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("Role Group Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      // await setFieldValue("client_id", "");
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "rolegroup/update",
        token: cookies.t,
        params: {
          id: id,
        },
        data: values,
      });

      if (res.data.status) {
        refetchData();
        toast.success("Role Group Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => (
        <Form>
          <div className="row mt-3">
            <div className="col-3">
              <TagDropdown
                placeholder="Client Name"
                data={clientData}
                onChange={(option) => {
                  setFieldValue("client_id", option?.value);
                  setFieldValue("client_name", option?.label);
                }}
                handleBlur={(e) => {
                  setFieldTouched("client_id", true);
                  handleBlur(e);
                }}
                selectedValue={values.client_id}
                format="all"
                menuOpen={true}
                name="client_id"
                touched={touched.client_id}
                errors={errors.client_id}
                width="32.426%"
              />
            </div>
            <div className="col-3">
              <MyInput
                type="text"
                id="name"
                name="name"
                totalWidth={true}
                customholder="Enter Group Name"
              />
            </div>
            <div className="col-3"></div>
            <div className="col-3">
              {initialValues ? (
                <button
                  type="submit"
                  className="btn-smlg btn-primary w-50"
                  style={{ marginLeft: "45%" }}
                  disabled={!isValid || isSubmitting}
                >
                  Update
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              ) : (
                <div className="d-flex gap-4">
                  <button
                    type="button"
                    className="btn-smlg btn-outline-primary w-25 flex-1"
                    disabled={isSubmitting}
                    onClick={() => resetForm()}
                  >
                    Reset
                  </button>

                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-25 flex-1"
                    disabled={isSubmitting}
                  >
                    Add
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
