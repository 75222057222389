export const Rate_TypeData = [
  { label: "Floating", value: "Floating" },
  { label: "Flat", value: "Flat" },
];
export const DaysData = [
  { label: "360 Days", value: 360 },
  { label: "365- Typically, Don't use", value: 365 },
];
export const statusData = [
  { label: "Active", value: "Active" },
  { label: "Draft", value: "Draft" },
];
export const notestatusData = [
  { label: "Active", value: "Active" },
  { label: "Draft", value: "Draft" },
];
export const counterPartyType = [
  { label: "Client", value: "Client" },
  { label: "Obligor", value: "Obligor" },
  { label: "Supplier", value: "Supplier" },
  { label: "Pre-Client", value: "Pre-Client" },
];
export const NoteType = [
  { label: "General", value: "general" },
  { label: "Existing Client Q&A", value: "clientQ&A" },
  { label: "Internal Client Monitoring", value: "clientInternalMonitoring" },
  { label: "Prospective Client Q&A", value: "prospectiveQ&A" },
  { label: "Prospective Client Onboarding", value: "prospectiveOnboarding" },
  { label: "Existing Client Monitoring", value: "clientMonitoring" },
  { label: "Platform Update", value: "platformUpdate" },
  { label: "Broker Update", value: "brokerUpdate" },
];
export const BaseRateData = [
  { label: "SOFR-USD-1m", value: "SOFR-USD-1m" },
  { label: "SOFR-USD-3m", value: "SOFR-USD-3m" },
  { label: "BOE-GBP-2FL-6CAP", value: "BOE-GBP-2FL-6CAP" },
  { label: "BOE-GBP", value: "BOE-GBP" },
  { label: "Prime-WSJ", value: "Prime-WSJ" },
  { label: "Other...", value: "Other" },
];

export const BrokerTypeData = [
  { label: "Primary (Platform)", value: "Primary" },
  { label: "Secondary (Broker)", value: "Secondary" },
];
export const clientNameData = [{ label: "test", value: "test" }];
export const signerTypeData = [
  { label: "Client", value: "Client" },
  { label: "Supplier", value: "Supplier" },
  { label: "Obligor", value: "Obligor" },
];
export const CurrencyData = [
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
];
export const PolicyNameData = [
  { label: "Euler 2023 - HM", value: "Euler 2023 - HM" },
  { label: "TCU 22/23 - HM", value: "TCU 22/23 - HM" },
  { label: "Coface 22/23 - RBA", value: "Coface 22/23 - RBA" },
  { label: "Euler 22/23 - NES", value: "Euler 22/23 - NES" },
  { label: "Other", value: "Other" },
];
export const LimitTypeData = [
  { label: "Cancellable", value: "Cancellable" },
  { label: "Permanent", value: "Permanent" },
  { label: "Temporary", value: "Temporary" },
];
export const CarrierNameData = [
  { label: "Euler", value: "Euler" },
  { label: "TCU", value: "TCU" },
  { label: "Atradius", value: "Atradius" },
  { label: "Coface", value: "Coface" },
  { label: "Other", value: "Other" },
];
export const ReportingData = [
  { label: " Monthly 15 days", value: " Monthly 15 days" },
  { label: "Quarterly 15 days", value: "Quarterly 15 days" },
  { label: "Monthly 30 days", value: "Monthly 30 days" },
  { label: "Other", value: "Other" },
];

export const VerifiedData = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
  { label: "Pending", value: "Pending" },
];

export const GICSectorData = [
  { label: "10 Energy", value: "10 Energy" },
  { label: "15 Materials", value: "15 Materials" },
  { label: "20 Industrials", value: "20 Industrials" },
  { label: "25 Consumer Discretionary", value: "25 Consumer Discretionary" },
  { label: "30 Consumer Staples", value: "30 Consumer Staples" },
  { label: "35 Health Care", value: "35 Health Care" },
  { label: "40 Financials", value: "40 Financials" },
  { label: "45 Information Technology", value: "45 Information Technology" },
  { label: "50 Communication Services", value: "50 Communication Services" },
  { label: "55 Utilities", value: "55 Utilities" },
  { label: "60 Real Estate", value: "60 Real Estate" },
];
export const GICSIndustryData = [
  {
    label: "101010 Energy Equipment & Services",
    value: "101010 Energy Equipment & Services",
  },
  {
    label: "101020 Oil, Gas & Consumable Fuels",
    value: "101020 Oil, Gas & Consumable Fuels",
  },
  { label: "151010Chemicals", value: "151010 Chemicals" },
  {
    label: "151020 Construction Materials",
    value: "151020 Construction Materials",
  },
  {
    label: "151030 Containers & Packaging",
    value: "151030 Containers & Packaging",
  },
  { label: "151040 Metals & Mining", value: "151040	Metals & Mining" },
  {
    label: "151050 Paper & Forest Products",
    value: "151050 Paper & Forest Products",
  },
  { label: "201010 Aerospace & Defense", value: "201010	Aerospace & Defense" },
  { label: "201020 Building Products", value: "201020	Building Products" },
  {
    label: "201030 Construction & Engineering",
    value: "201030 Construction & Engineering",
  },
  { label: "201040 Electrical Equipment", value: "201040	Electrical Equipment" },
  {
    label: "201050 Industrial Conglomerates",
    value: "201050 Industrial Conglomerates",
  },
  { label: "201060 Machinery", value: "201060 Machinery" },
  {
    label: "201070 Trading Companies & Distributors",
    value: "201070 Trading Companies & Distributors",
  },
  {
    label: "202010 Commercial Services & Supplies",
    value: "202010 Commercial Services & Supplies",
  },
  {
    label: "202020 Professional Services	",
    value: "202020 Professional Services	",
  },
  {
    label: "203010 Air Freight & Logistics",
    value: "203010 Air Freight & Logistics",
  },
  { label: "203020 Passenger Airlines", value: "203020	Passenger Airlines" },
  {
    label: "203030 Marine Transportation",
    value: "203030 Marine Transportation",
  },
  {
    label: "203040 Ground Transportation",
    value: "203040 Ground Transportation",
  },
  {
    label: "203050 Transportation Infrastructure",
    value: "203050 Transportation Infrastructure",
  },
  {
    label: "251010 Automobile Components",
    value: "251010 Automobile Components",
  },
  { label: "251020 Automobiles", value: "251020	Automobiles" },
  { label: "252010 Household Durables", value: "252010 Household Durables" },
  { label: "252020 Leisure Products", value: "252020 Leisure Products" },
  {
    label: "252030 Textiles, Apparel & Luxury Goods",
    value: "252030 Textiles, Apparel & Luxury Goods",
  },
  {
    label: "253010 Hotels, Restaurants & Leisure",
    value: "253010 Hotels, Restaurants & Leisure",
  },
  {
    label: "253020 Diversified Consumer Services",
    value: "253020 Diversified Consumer Services",
  },
  { label: "255010 Distributors", value: "255010 Distributors" },
  { label: "255030 Broadline Retail", value: "255030 Broadline Retail" },
  { label: "255040 Specialty Retail", value: "255040	Specialty Retail" },
  {
    label: "301010 Consumer Staples Distribution & Retail",
    value: "301010 Consumer Staples Distribution & Retail",
  },
  { label: "302010 Beverages", value: "302010	Beverages" },
  { label: "302020 Food Products", value: "302020	Food Products" },
  { label: "302030 Tobacco", value: "302030	Tobacco" },
  { label: "303010 Household Products", value: "303010	Household Products" },
  {
    label: "303020 Personal Care Products",
    value: "303020 Personal Care Products",
  },
  {
    label: "351010 Health Care Equipment & Supplies",
    value: "351010 Health Care Equipment & Supplies",
  },
  {
    label: "351020 Health Care Providers & Services",
    value: "351020 Health Care Providers & Services",
  },
  {
    label: "351030 Health Care Technology",
    value: "351030 Health Care Technology",
  },
  { label: "352010 Biotechnology", value: "352010	Biotechnology" },
  { label: "352020 Pharmaceuticals", value: "352020	Pharmaceuticals" },
  {
    label: "352030 Life Sciences Tools & Services",
    value: "352030 Life Sciences Tools & Services",
  },
  { label: "401010 Banks", value: "401010	Banks" },
  { label: "402010 Financial Services", value: "402010	Financial Services" },
  { label: "402020 Consumer Finance", value: "402020	Consumer Finance" },
  { label: "402030 Capital Markets", value: "402030	Capital Markets" },
  {
    label: "402040 Mortgage Real Estate Investment Trusts (REITs)",
    value: "402040 Mortgage Real Estate Investment Trusts (REITs)",
  },
  { label: "403010 Insurance", value: "403010	Insurance" },
  { label: "451020 IT Services", value: "451020	IT Services" },
  { label: "451030 Software", value: "451030	Software" },
  {
    label: "452010 Communications Equipment",
    value: "452010 Communications Equipment",
  },
  {
    label: "452020 Technology Hardware, Storage & Peripherals",
    value: "452020 Technology Hardware, Storage & Peripherals",
  },
  {
    label: "452030 Electronic Equipment, Instruments & Components",
    value: "452030 Electronic Equipment, Instruments & Components",
  },
  {
    label: "453010 Semiconductors & Semiconductor Equipment",
    value: "453010 Semiconductors & Semiconductor Equipment",
  },
  {
    label: "501010 Diversified Telecommunication Services",
    value: "501010 Diversified Telecommunication Services",
  },
  {
    label: "501020 Wireless Telecommunication Services",
    value: "501020 Wireless Telecommunication Services",
  },
  { label: "502010 Media", value: "502010	Media" },
  { label: "502020 Entertainment", value: "502020	Entertainment" },
  {
    label: "502030 Interactive Media & Services",
    value: "502030 Interactive Media & Services",
  },
  { label: "551010 Electric Utilities", value: "551010 Electric Utilities" },
  { label: "551020 Gas Utilities", value: "551020	Gas Utilities" },
  { label: "551030 Multi-Utilities", value: "551030	Multi-Utilities" },
  { label: "551040 Water Utilities", value: "551040 Water Utilities" },
  {
    label: "551050 Independent Power and Renewable Electricity Producers",
    value: "551050 Independent Power and Renewable Electricity Producers",
  },
  { label: "601010 Diversified REITs", value: "601010	Diversified REITs" },
  { label: "601025 Industrial REITs", value: "601025	Industrial REITs" },
  { label: "601030 Hotel & Resort REITs", value: "601030	Hotel & Resort REITs" },
  { label: "601040 Office REITs", value: "601040	Office REITs" },
  { label: "601050 Health Care REITs", value: "601050	Health Care REITs" },
  { label: "601060 Residential REITs", value: "601060	Residential REITs" },
  { label: "601070 Retail REITs", value: "601070	Retail REITs" },
  { label: "601080 Specialized REITs", value: "601080	Specialized REITs" },
  {
    label: "602010 Real Estate Management & Development",
    value: "602010 Real Estate Management & Development",
  },
];

export const transactionType = [
  { label: "Supplier Credit", value: "Supplier Credit" },
  { label: "Factoring/AR", value: "Factoring/AR" },
  { label: "Note", value: "Note" },
  { label: "Revolver", value: "Revolver" },
];
export const funcdingTransactionType = [
  { label: "Supplier Credit", value: "Supplier Credit" },
  { label: "Factoring/AR", value: "Factoring/AR" },
  { label: "Note", value: "Note" },
];

export const agreementCounterPartyData = [
  {
    label: "Highmore Financing Co. I, LLC",
    value: "Highmore Financing Co. I, LLC",
  },
  {
    label: "Highmore Financing Co. II, LLC",
    value: "Highmore Financing Co. II, LLC",
  },
  {
    label: "Highmore Financing Co. III, LLC",
    value: "Highmore Financing Co. III, LLC",
  },
  {
    label: "Highmore Financing Co. IV, LLC",
    value: "Highmore Financing Co. IV, LLC",
  },
  {
    label: "Highmore Financing Co. V, LLC",
    value: "Highmore Financing Co. V, LLC",
  },
  {
    label: "Highmore Financing Co. VI, LP",
    value: "Highmore Financing Co. VI, LP",
  },
  {
    label: "Highmore Financing Co. VII, LP",
    value: "Highmore Financing Co. VII, LP",
  },
  {
    label: "Highmore Financing Co. VIII, LP",
    value: "Highmore Financing Co. VIII, LP",
  },
  {
    label: "Highmore Financing Co. IX, LP",
    value: "Highmore Financing Co. IX, LP",
  },
  {
    label: "Highmore Financing Co. X, LP",
    value: "Highmore Financing Co. X, LP",
  },
  {
    label: "Highmore Financing Co. XI, LP",
    value: "Highmore Financing Co. XI, LP",
  },
  {
    label: "Highmore Financing Co. XII, LP",
    value: "Highmore Financing Co. XII, LP",
  },
  {
    label: "Highmore Financing Co. XIV, LP",
    value: "Highmore Financing Co. XIV, LP",
  },
  {
    label: "TBD Placeholder",
    value: "TBD Placeholder",
  },
];

export const documentInternalEnitity = [
  {
    label: "Highmore Financing Co. I, LLC",
    value: "Highmore Financing Co. I, LLC",
  },
  {
    label: "Highmore Financing Co. II, LLC",
    value: "Highmore Financing Co. II, LLC",
  },
  {
    label: "Highmore Financing Co. III, LLC",
    value: "Highmore Financing Co. III, LLC",
  },
  {
    label: "Highmore Financing Co. IV, LLC",
    value: "Highmore Financing Co. IV, LLC",
  },
  {
    label: "Highmore Financing Co. V, LLC",
    value: "Highmore Financing Co. V, LLC",
  },
  {
    label: "Highmore Financing Co. VI, LP",
    value: "Highmore Financing Co. VI, LP",
  },
  {
    label: "Highmore Financing Co. VII, LP",
    value: "Highmore Financing Co. VII, LP",
  },
  {
    label: "Highmore Financing Co. VIII, LP",
    value: "Highmore Financing Co. VIII, LP",
  },
  {
    label: "Highmore Financing Co. IX, LP",
    value: "Highmore Financing Co. IX, LP",
  },
  {
    label: "Highmore Financing Co. X, LP",
    value: "Highmore Financing Co. X, LP",
  },
  {
    label: "Highmore Financing Co. XI, LP",
    value: "Highmore Financing Co. XI, LP",
  },
  {
    label: "Highmore Financing Co. XII, LP",
    value: "Highmore Financing Co. XII, LP",
  },
  {
    label: "Highmore Financing Co. XIV, LP",
    value: "Highmore Financing Co. XIV, LP",
  },
  { label: "Abbey HM-JN LLC", value: "Abbey HM-JN LLC" },
  { label: "Tosa HM-NWG, LLC", value: "Tosa HM-NWG, LLC" },
  { label: "Pohan HM-BFG, LLC", value: "Pohan HM-BFG, LLC" },
  { label: "Becketts HM-AA, LLC", value: "Becketts HM-AA, LLC" },
  { label: "Stowe HM-PB, LLC", value: "Stowe HM-PB, LLC" },
  { label: "Piratella HM-RPA, LLC", value: "Piratella HM-RPA, LLC" },
  {
    label: "Highmore Group Advisors, LLC",
    value: "Highmore Group Advisors, LLC",
  },
  {
    label: "ABC Entity (Placeholder),LLC",
    value: "ABC Entity (Placeholder),LLC",
  },
];

export const fundingPaymentTerms = [
  { label: "7 Days", value: "7 Days" },
  { label: "30 Days", value: "30 Days" },
  { label: "60 Days", value: "60 Days" },
  { label: "90 Days", value: "90 Days" },
  { label: "120 Days", value: "120 Days" },
];

export const fundingStatusData = [{ label: "Reject", value: "Reject" }];

export const NDATypeData = [
  { label: "General Mutual NDA", value: "General Mutual NDA" },
  { label: "Company NDA", value: "Company NDA" },
];

export const NDADocumentTerms = [
  { label: "Twelve (12) months", value: "Twelve (12) months" },
  { label: "Twenty Four (24) months", value: "Twenty Four (24) months" },
  { label: "Thirty Six (36) months", value: "Thirty Six (36) months" },
  { label: "Fourty Eight (48) months", value: "Fourty Eight (48) months" },
];
export const TermSheetTypeData = [
  { label: "Supplier Credit Term Sheet", value: "Supplier Credit Term Sheet" },
  {
    label: "Accounts Receivable Line Term Sheet",
    value: "Accounts Receivable Line Term Sheet",
  },
  {
    label: "Account Receivable + Inventory Term Sheet",
    value: "Account Receivable + Inventory Term Sheet",
  },
  { label: "Factoring Term Sheet", value: "Factoring Term Sheet" },
  {
    label: "Confidential Invoice Discounting Term Sheet",
    value: "Confidential Invoice Discounting Term Sheet",
  },
  { label: "Promissory Note Term Sheet", value: "Promissory Note Term Sheet" },
];

export const LawData = [
  { label: "New York law", value: "New York law" },
  { label: "English law", value: "English law" },
];

export const TaxNoticeData = [
  {
    label:
      "The Buyer is not in receipt of any notices from IRS or other Government authorities",
    value:
      "The Buyer is not in receipt of any notices from IRS or other Government authorities",
  },
  {
    label:
      "The Buyer is not in receipt of any notices from HMRC or other Government authorites",
    value:
      "The Buyer is not in receipt of any notices from HMRC or other Government authorites",
  },
];
export const taxAccessData = [
  {
    label:
      "Signed IRS form 8821 allowing direct access to taxreturns and Tax Lien",
    value:
      "Signed IRS form 8821 allowing direct access to taxreturns and Tax Lien",
  },
  {
    label:
      "HMRC statement balances for all relevant business taxes within 45 days of quarter end",
    value:
      "HMRC statement balances for all relevant business taxes within 45 days of quarter end",
  },
];
export const TaxLienData = [
  {
    label: "Immediate notification upon any IRS Notice to Levy or Levy",
    value: "Immediate notification upon any IRS Notice to Levy or Levy",
  },
  {
    label:
      "Petitions to wind up Immediate notification upon any HMRC Petitions to wind up",
    value:
      "Petitions to wind up Immediate notification upon any HMRC Petitions to wind up",
  },
];

export const FunderLookOption = [
  {
    label:
      "Right of First Offer ROFO, Right of First Refusal ROFR, and Last Look LL on additional and/or follow on facilities ",
    value:
      "Right of First Offer ROFO, Right of First Refusal ROFR and Last Look LL on additional and/or follow on facilities ",
  },
  {
    label:
      "Right of First Offer ROFO, Right of First Refusal ROFR on additional and/or follow on facilities",
    value:
      "Right of First Offer ROFO, Right of First Refusal ROFR on additional and/or follow on facilities",
  },
  {
    label:
      "Right of First Offer ROFO and Last Look LL on additional and/or follow on facilities",
    value:
      "Right of First Offer ROFO and Last Look LL on additional and/or follow on facilities",
  },
  {
    label:
      "Right of First Refusal ROFR and Last Look LL on additional and/or follow on facilities",
    value:
      "Right of First Refusal ROFR and Last Look LL on additional and/or follow on facilities",
  },
  {
    label:
      "Right of First Offer ROFO on additional and/or follow on facilities",
    value:
      "Right of First Offer ROFO on additional and/or follow on facilities",
  },
  {
    label:
      "Right of First Refusal ROFR on additional and/or follow on facilities",
    value:
      "Right of First Refusal ROFR on additional and/or follow on facilities",
  },
  {
    label: " Last Look LL on additional and/or follow on facilities",
    value: " Last Look LL on additional and/or follow on facilities",
  },
];
export const EquityWrrants = [
  {
    label: "1% penny warrants in the company",
    value: "1% penny warrants in the company",
  },
  {
    label: "2% penny warrants in the company",
    value: "2% penny warrants in the company",
  },
  {
    label: "5% penny warrants in the company",
    value: "5% penny warrants in the company",
  },
  {
    label: "10% penny warrants in the company",
    value: "10% penny warrants in the company",
  },
];

export const sharePledgeData = [
  {
    label:
      "The majority shareholder(s) of the company shall pledge their shares of the company to the Funder up to the Facility Amount",
    value:
      "The majority shareholder(s) of the company shall pledge their shares of the company to the Funder up to the Facility Amount",
  },
];
export const insuranceTermSheetData = [
  {
    label:
      "Such Receivable is credit insured by and insurer acceptable to the funder",
    value:
      "Such Receivable is credit insured by and insurer acceptable to the funder",
  },
];
export const maxTermsData = [
  { label: "Upto 30", value: "Upto 30" },
  { label: "Upto 60", value: "Upto 60" },
  { label: "Upto 90", value: "Upto 90" },
  { label: "Upto 120", value: "Upto 120" },
];
export const geographicConcentrationData = [
  { label: "5%", value: "5%" },
  { label: "10%", value: "10%" },
  { label: "15%", value: "15%" },
  { label: "20%", value: "20%" },
  { label: "25%", value: "25%" },
  { label: "30%", value: "30%" },
  { label: "50%", value: "50%" },
  { label: "100%", value: "100%" },
];
export const earlyTerminationFee = [
  {
    label:
      "Interest to be paid for all outstanding tranches through the entire life of the such tranche",
    value:
      "Interest to be paid for all outstanding tranches through the entire life of the such tranche",
  },
  { label: "2% of Facility Amount", value: "2% of Facility Amount" },
  { label: "None", value: "None" },
];

export const drawTrachemaxTerm = [
  { label: "60", value: "60" },
  { label: "90", value: "90" },
  { label: "120", value: "120" },
  { label: "150", value: "150" },
  { label: "180", value: "180" },
  { label: "270", value: "270" },
  { label: "360", value: "360" },
];
export const maxObligorConst = [
  { label: "5%", value: "5%" },
  { label: "10%", value: "10%" },
  { label: "15%", value: "15%" },
  { label: "20%", value: "20%" },
  { label: "25%", value: "25%" },
  { label: "30%", value: "30%" },
  { label: "50%", value: "50%" },
  { label: "100%", value: "100%" },
];

export const DocumentsTools = [
  { label: "NDA", value: "NDA" },
  { label: "Term sheet", value: "Term sheet" },
  { label: "Legal", value: "Legal" },
  { label: "Forms", value: "Forms" },
  { label: "Expense", value: "Expense" },
];
export const legalData = [
  { label: "Broker Agreement", value: "Broker Agreement" },
  {
    label: "Supplier Credit Agreement(US)",
    value: "Supplier Credit Agreement(US)",
  },
  {
    label: "Supplier Credit Agreement(UK)",
    value: "Supplier Credit Agreement(UK)",
  },
  {
    label: "Supplier Credit Guarantee(US Corp + Personal)",
    value: "Supplier Credit Guarantee(US Corp + Personal)",
  },
  {
    label: "Supplier Credit Secured Guarantee (US Corp)",
    value: "Supplier Credit Secured Guarantee (US Corp)",
  },
  {
    label: "Supplier Credit Guarantee (UK Personal)",
    value: "Supplier Credit Guarantee (UK Personal)",
  },
  {
    label: "Supplier Credit Guarantee (UK Corp)",
    value: "Supplier Credit Guarantee (UK Corp)",
  },
  {
    label: "Supplier Credit Side Letter(US)",
    value: "Supplier Credit Side Letter(US)",
  },
  {
    label: "Supplier Credit Side Letter(UK)",
    value: "Supplier Credit Side Letter(UK)",
  },
  {
    label: "Electornic Signature Agreement(US)",
    value: "Electornic Signature Agreement(US)",
  },
  {
    label: "Electornic Signature Agreement(UK)",
    value: "Electornic Signature Agreement(UK)",
  },
  {
    label: "Debenture-Second Ranking SC (UK)",
    value: "Debenture-Second Ranking SC(UK)",
  },
];
export const formtermsData = [
  { label: "Credit Application Form", value: "Credit Application Form" },
  {
    label: "Personal Financial Statement",
    value: "Personal Financial Statement",
  },
  { label: "Catalyst Release Form", value: "Catalyst Release Form" },
  { label: "Trade Reference Form(Full)", value: "Trade Reference Form(Full)" },
  {
    label: "Trade Reference Form(No Doc Request)",
    value: "Trade Reference Form(No Doc Request)",
  },
  { label: "Supplier Contact List", value: "Supplier Contact List" },
  { label: "Customer Contact List", value: "Customer Contact List" },
  { label: "Debt Schedule", value: "Debt Schedule" },
];
export const ExpenseTermsData = [
  { label: "Expense Approval Form", value: "Expense Approval Form" },
  {
    label: "Expense Approval Form (All Funds)",
    value: "Expense Approval Form (All Funds)",
  },
];
export const collateralData = [
  {
    label:
      "First-position all-asset UCC-1 on the company and corporate Guarantors",
    value:
      "First-position all-asset UCC-1 on the company and corporate Guarantors",
  },
  {
    label:
      "First position all-assets fixed and floating charge on the company and corporate Guarantors",
    value:
      "First position all-assets fixed and floating charge on the company and corporate Guarantors",
  },
];
export const maxInventoryConcs = [
  { label: "5%", value: "5%" },
  { label: "10%", value: "10%" },
  { label: "15%", value: "15%" },
  { label: "20%", value: "20%" },
  { label: "25%", value: "25%" },
  { label: "30%", value: "30%" },
  { label: "50%", value: "50%" },
  { label: "100%", value: "100%" },
];
export const maximumInvoiceTennor = [
  { label: "0", value: "0" },
  { label: "30", value: "30" },
  { label: "60", value: "60" },
  { label: "90", value: "90" },
  { label: "120", value: "120" },
  { label: "150", value: "150" },
  { label: "180", value: "180" },
  { label: "270", value: "270" },
  { label: "360", value: "360" },
];

export const monthData = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const rateNameData = [
  { label: "SOFR-USD-1m", value: "SOFR-USD-1m" },
  { label: "SOFR-USD-3m", value: "SOFR-USD-3m" },
  { label: "BOE-GBP-2FL-6CAP", value: "BOE-GBP-2FL-6CAP" },
  { label: "BOE-GBP", value: "BOE-GBP" },
  { label: "Prime-WSJ", value: "Prime-WSJ" },
];

export const SignerStaticData = [
  { label: "henry.beggin@highmore.com", value: "henry.beggin@highmore.com" },
  { label: "dipak.jogia@highmore.com", value: "dipak.jogia@highmore.com" },
  { label: "Jennie.jiang@highmore.com", value: "Jennie.jiang@highmore.com" },
  { label: "tradefinance@highmore.com", value: "tradefinance@highmore.com" },
  { label: "notifications@supplyfi.app", value: "notifications@supplyfi.app" },
];
