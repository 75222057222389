import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../../atoms/PageHeader';
import PageCard from '../../../../atoms/PageCard';
import Search from '../../../../atoms/Search';
import CustomButton from '../../../../atoms/CustomButton';
import CustomDatagrid from '../../../../molecules/CustomDatagrid';
import { setLoading } from '../../../../../store/slices/loaderSlice';
import View from '../../../../icons/View';
import Edit from '../../../../icons/EditBlue';
import Delete from '../../../../icons/Delete';
import { getData } from '../../../../../services';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import {
  confimationStyles,
  errorStyles,
} from '../../../../../assets/styles/components/toast';
import { useDelete } from '../../../../../hooks/useDelete';
import DeleteModal from '../../../../atoms/modals/DeleteModal';
import { useSelector } from 'react-redux';
import NoContentCard from '../../../../atoms/NoContentCard';
import NoContentIcon from '../../../../icons/Counterparty';
import { Heading } from '../../../../atoms/Heading';
import Pagination from '../../../../atoms/Pagination';
import HorizontalMenu from '../../../../icons/HorizontalMenu';
import CustomMenu from '../../../../molecules/CustomMenu';
import moment from 'moment';

const RoleMangement = () => {
  const [cookies] = useCookies(['t']);
  const navigate = useNavigate();

  const accessData = useSelector((data) => data?.accessData?.accessData);

  const [searchedValue, setSearchedValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: '',
    name: '',
  });

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: 'Edit',
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: 'Delete',
      onClick: () => {
        handleDeleteModal(selectedRow);
        setAnchorEl(null);
      },
    },
  ];

  const getAccessData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: '/role',
        params: {
          search: searchedValue,
          page: currentPage,
          limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getAccessData();
  }, [getAccessData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'role_name',
      headerName: 'Role',
      flex: 1,
      sortable: true,
    },

    {
      field : 'createdAt', 
      headerName : 'Created At',
      flex : 1, 
      sortable : true,
      renderCell : (params) => {
        return (
          <>
          <span>
           {moment(params.row?.createdAt).format("YYYY-MM-DD")}
          </span>
          </>
        )
       }
    }
  ];

  if (
    accessData?.['Access Management']?.is_edit ||
    accessData?.['Access Management']?.is_delete ||
    true
  ) {
    columns.push({
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
          //   <div className="table-icons-container d-flex align-items-center">
          //     <div onClick={() => navigate(`edit/${params.row._id}`)}>
          //       <Edit />
          //     </div>

          //     <div onClick={() => handleDeleteModal(params.row)} className="mb-1">
          //       <Delete />
          //     </div>
          //   </div>
        );
      },
    });
  }

  // deletion --------------------------------------------
  const deleteRole = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data._id,
      name: data.role_name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteRole('role/delete', 'Role', {
        id: modalState.modalId,
      });
      getAccessData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error('An Error Occured While Deleting', {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard title="No Record Found" type="Notes" />
        ) : (
          <NoContentCard
            title="No Role Added Yet!"
            subtitle="Click on Add Role button to add a Role"
            tag="Add New Role"
            handleClick={() => navigate('add')}
            type="Notes"
          />
        )}
      </>
    );
  });

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Role Management</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          {/* {accessData?.['Access Management']?.is_create && ( */}
          <CustomButton
            text="Add New Role"
            type="btn-primary"
            handleClick={() => navigate('add')}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
          />
          {/* )} */}
        </div>
      </div>

      <CustomDatagrid
        getRowId={(row) => row._id}
        rows={tableData?.data?.[0]?.roleData || []}
        columns={columns}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />

      {tableData?.data?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.data?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Role"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default RoleMangement;
