import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";

const AllocatedModal = ({ modalStateAllocated, setModalStateAllocated }) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});

  console.log("modalStateApproved", modalStateAllocated);

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateAllocated.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClientById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData?.client_id) {
      getClientById();
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateAllocated?.modalId) {
      getFundingById();
    }
  }, [modalStateAllocated?.modalId]);

  const handleApproveFunding = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateAllocated.modalId,
        },
        data: {
          status: "Allocated",
        },
      });
      if (res) {
        toast.success("Funding Submitted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setModalStateAllocated(() => ({
        isModalOpen: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <div>Allocated</div>
      <div className={style.buttonStyle}>
        <CustomButton
          text="Credit Info"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {}}
        />
        <CustomButton
          text="Exiting Allcation"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {}}
        />
        <CustomButton
          text="Allcation History"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {}}
        />
        <CustomButton
          text="Eligibility"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {}}
        />

        <CustomButton
          text="Other"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {}}
        />
      </div>
      <div>
        <div className="row">
          <label className={style.terms}>Asset Info</label>
          <div className="col-6">
            <div className={style.value}>Cost</div>
          </div>
          <div className="col-6">
            <div className={style.value}>12345</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Face Value</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData.face_value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Currency</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.currency}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Schedule status</div>
          </div>
          <div className="col-6">
            <div className={style.value}>document complete</div>
          </div>
        </div>
        <div className="row">
          <label className={style.terms}>Obligor Information</label>
          <div className="col-6">
            <div className={style.value}>Name</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.counter_name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Internal Credit Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.credit_limit}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Credit Used</div>
          </div>
          <div className="col-6">
            <div className={style.value}>123</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.value}>Credit Remaining</div>
          </div>
          <div className="col-6">
            <div className={style.value}>123</div>
          </div>
        </div>
      </div>

      <div className={style.buttonStyle}>
        <CustomButton
          text="Confirm Allocation"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleApproveFunding();
          }}
        />
        <CustomButton
          text="Cancel"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setModalStateAllocated(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default AllocatedModal;
