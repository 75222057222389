import React, { useEffect, useState } from "react";
import { getData } from "../../../services";
import { useCookies } from "react-cookie";
import MyDropdown from "../../atoms/MyDropdown/TagDropdown";

const CounterDropDown = ({
  onChange,
  selectedValue,
  isError,
  touched,
  handleBlur,
  errors,
}) => {
  const [data, setData] = useState();
  const [cookies] = useCookies(["t"]);

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "couterParty/getAllCountersList",
        token: cookies.t,
        params: {
          counterparty_type: ["Client"],
        },
      });
      setData(res.data);
    })();
  }, []);

  return (
    <MyDropdown
      placeholder="Client Name"
      data={data}
      onChange={onChange}
      selectedValue={selectedValue}
      format="all"
      isError={isError}
      touched={touched}
      handleBlur={handleBlur}
      errors={errors}
      tagSplit={false}
    />
  );
};

export default CounterDropDown;
