export const forceTerminl = [
  { label: 'Midnight', value: 'Midnight' },
  { label: 'After 24hrs', value: 'After 24hrs' },
];

export const defaultInviteStatus = [
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Pending', value: 'Pending' },
];

export const statusData = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];

export const userRoles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Viewer', value: 'Viewer' },
];

export const statusInvites = [
  { label: 'Ongoing', value: 'Ongoing' },
  { label: 'Completed', value: 'Completed' },
];

export const businessTypes = [
  { value: 'Private Limited', label: 'Private Limited' },
  { value: 'Public Limited', label: 'Public Limited' },
  { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
  { value: 'Individual', label: 'Individual' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'Limited Liability Company', label: 'Limited Liability Company' },
  {
    value: 'Limited Liability Partnership',
    label: 'Limited Liability Partnership',
  },
  { value: 'Corporation', label: 'Corporation' },
  {
    value: 'Non-Government Organization',
    label: 'Non-Government Organization',
  },
];

export const modesData = [
  { label: 'Employee', value: 1 },
  { label: 'Business', value: 2 },
];

export const convenantFrequency = [
  { value: 'At-sight', label: 'At-sight' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Bi-annual', label: 'Bi-annual' },
  { value: 'Annual', label: 'Annual' }
]

export const monthly_shift = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 }
]

export const reportingDays = [
  {value : 0, label : 0},
  {value : 10, label : 10},
  {value : 15, label : 15},
  {value : 30, label : 30},
  {value : 45, label : 45},
  {value : 60, label : 60},
  {value : 90, label : 90},
  {value : 120, label : 180},
]
