import { useEffect, useState } from "react";
import { getData, patchData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import MyDropdown from "../../../../atoms/MyDropdown";
import MyInput from "../../../../atoms/MyInput";
import { Formik } from "formik";
import CustomButton from "../../../../atoms/CustomButton";
import moment from "moment";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import * as Yup from "yup";
import TextInput from "../../../../atoms/TextInput";

const ViewDraftFunding = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [signerData, setSignerData] = useState([]);
  const [selectedSigner, setSelectedSigner] = useState();
  const [selectedSignerById, setSelectedSignerId] = useState();
  const imagesName =
    fundingData?.documents?.[0]?.split("/")?.[
      fundingData?.documents?.[0]?.split("/")?.length - 1
    ];

  console.log("selectedSigner", selectedSigner?.length);

  const getFundingDataById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getClientDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      const tempSigner = res?.data?.signerDetails?.map((item) => {
        return {
          label: item?.email,
          value: item?._id,
        };
      });
      setSignerData(tempSigner);
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getFundingDataById();
    }
  }, [id]);

  useEffect(() => {
    if (fundingData?.client_id) {
      getClientDataById();
    }
  }, [fundingData?.client_id]);
  console.log("selectedSigner", selectedSigner);

  const handleSubmit = async () => {
    const dataToSend = {
      client_id: fundingData?.client_id,
      client_name: fundingData?.client_name,
      currency: fundingData?.currency,
      documents: fundingData?.documents,
      due_date: fundingData?.due_date,
      face_value: fundingData?.face_value,
      referance: fundingData?.referance,
      status: "Advance Request",
      tenure_days: fundingData?.tenure_days,
      transaction_type: fundingData?.transaction_type,

      payment_terms: fundingData?.payment_terms,
      invoice_number: fundingData?.invoice_number,
      supplier_id: fundingData?.supplier_id,
      supplier_name: fundingData?.supplier_name,

      obligor_name: fundingData?.obligor_name,
      obligor_id: fundingData?.obligor_id,
      max_advance: fundingData?.max_advance,
      req_advance_value: fundingData?.req_advance_value,
      invoice_date: fundingData?.invoice_date,

      signer_id: selectedSigner?.[0]?._id,
      signer_name: selectedSignerById,
      signer_email: selectedSigner?.[0]?.email,
    };
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: fundingData?._id,
        },
        data: dataToSend,
      });
      if (res) {
        toast.success("Funding Request Schedule Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/funding");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Breadcrumb
        labels={["Funding", "Custom Request Schedule"]}
        stepsToRemove={2}
      />
      <Heading className="HeadingStyle">Custom Request Schedule</Heading>
      <div>
        <Heading className={style.terms}>Terms</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-3">
            <div className={style.label}>Client Entity</div>
            <label className={style.value}>{clientData?.counter_name}</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Client Address</div>
            <label className={style.value}>{clientData?.address}</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Agreement Entity</div>
            <label className={style.value}>
              {clientData?.agreement_counter}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Master Agreement Date</div>
            <label className={style.value}>
              {moment(clientData?.agreement_date).format("YYYY-MM-DD")}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Schedule Template ID</div>
            <label className={style.value}>--</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Date</div>
            <label className={style.value}>
              {moment(clientData?.createdAt).format("YYYY-MM-DD")}
            </label>
          </div>
        </div>
      </div>
      <div>
        <Heading className={style.terms}>UnderLying Details</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-3">
            <div className={style.label}>ID</div>
            <label className={style.value}>---</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Obligor Name</div>
            <label className={style.value}>
              {fundingData?.obligor_name ? fundingData?.obligor_name : "---"}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Supplier Name</div>
            <label className={style.value}>
              {fundingData?.supplier_name ? fundingData?.supplier_name : "---"}
            </label>
          </div>
          <div className="col-3">
            <div className={style.label}>Reference Number</div>
            <label className={style.value}>
              {fundingData?.referance ? fundingData?.referance : "---"}
            </label>
          </div>
        </div>
      </div>
      <div>
        <Heading className={style.terms}>Funding Details</Heading>
        <div className={`row ${style.fundingDetails}`}>
          <div className="col-3">
            <div className={style.label}>Inv Date (or Similar)</div>
            <label className={style.value}>264fcghyt23532</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Due Date</div>
            <label className={style.value}>16 Jul 2024</label>
          </div>
          {fundingData?.payment_terms && (
            <div className="col-3">
              <div className={style.label}>Payment Terms</div>
              <label className={style.value}>
                {fundingData?.payment_terms}
              </label>
            </div>
          )}
          {fundingData?.invoice_number && (
            <div className="col-3">
              <div className={style.label}>Invoice Number</div>
              <label className={style.value}>
                {fundingData?.invoice_number}
              </label>
            </div>
          )}
          {fundingData?.referance && (
            <div className="col-3">
              <div className={style.label}>Reference</div>
              <label className={style.value}>{fundingData?.referance}</label>
            </div>
          )}
          {fundingData?.tenure_days && (
            <div className="col-3">
              <div className={style.label}>Tennor Days</div>
              <label className={style.value}>{fundingData?.tenure_days}</label>
            </div>
          )}
          {fundingData?.transaction_type && (
            <div className="col-3">
              <div className={style.label}>Transaction Type</div>
              <label className={style.value}>
                {fundingData?.transaction_type}
              </label>
            </div>
          )}
          {fundingData?.max_advance && (
            <div className="col-3">
              <div className={style.label}>Max Advance</div>
              <label className={style.value}>{fundingData?.max_advance}</label>
            </div>
          )}
          {fundingData?.req_advance_value && (
            <div className="col-3">
              <div className={style.label}>Request Advance Value</div>
              <label className={style.value}>
                {fundingData?.req_advance_value}
              </label>
            </div>
          )}
          <div className="col-3">
            <div className={style.label}>Face Value</div>
            <label className={style.value}>{fundingData?.face_value}</label>
          </div>
          <div className="col-3">
            <div className={style.label}>Currency</div>
            <label className={style.value}>{fundingData?.currency}</label>
          </div>
        </div>
      </div>
      <div>
        <Heading className={style.terms}>Signer Info</Heading>
        <div className="row">
          <Formik
            initialValues={{
              signer_info: "",
            }}
            validationSchema={Yup.object().shape({
              signer_info: Yup.string().required("Signer Info is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              touched,
              errors,
              values,
              handleBlur,
              handleSubmit,
              setFieldTouched,
            }) => (
              <>
                <div className="col-4">
                  <MyDropdown
                    placeholder="Signer Info"
                    data={signerData}
                    onChange={async (option) => {
                      const tempData = clientData?.signerDetails?.filter(
                        (item) => item?._id === option,
                      );
                      const res = await getData({
                        endpoint: "signer/getSignerById",
                        token: cookies.t,
                        params: {
                          id: tempData?.[0]?._id,
                        },
                      });
                      console.log("res", res);
                      const name =
                        res?.data?.first_name + " " + res?.data?.last_name;
                      setSelectedSignerId(name);
                      setSelectedSigner(tempData);
                      setFieldValue("signer_info", tempData?.[0]?._id);
                    }}
                    selectedValue={values?.signer_info}
                    touched={touched.signer_info}
                    errors={errors.signer_info}
                    handleBlur={(e) => {
                      setFieldTouched("signer_info", true);
                      handleBlur(e);
                    }}
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    customholder="Signer Name"
                    type="text"
                    name="signer_name"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    value={selectedSignerById}
                    readOnly
                  />
                </div>
                <div className="col-4">
                  <TextInput
                    customholder="Email"
                    type="text"
                    name="signer_email"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    value={selectedSigner?.[0]?.email}
                    readOnly
                  />
                </div>
                <Heading className={`${style.terms} mt-4`}>
                  Generate and Send Funding Request
                </Heading>
                <div className="col-4">
                  <CustomButton
                    type="btn-primary"
                    text="Generate Schedule"
                    size="btn-lg"
                    classes={style.buttonStyle}
                    handleClick={() => {
                      handleSubmit(values);
                    }}
                  />
                </div>
                <div className="col-4">
                  <MyInput
                    customholder="Document Status"
                    type="text"
                    name="Document Status"
                    onBlur={(e) => {
                      // handleBlur(e);
                    }}
                    InputHeight="small"
                    value={imagesName}
                    readOnly
                  />
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default ViewDraftFunding;
