import { useParams } from "react-router-dom";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services";
import { useEffect, useState } from "react";
import moment from "moment/moment";

const ReviewView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [paymentInfoData, setPaymentInfoData] = useState({});

  const getPaymentInfoById = async () => {
    try {
      const res = await getData({
        endpoint: "payment/getPaymentById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setPaymentInfoData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getPaymentInfoById();
    }
  }, [id]);

  return (
    <>
      <Heading className="HeadingStyle">Payment Assignment</Heading>
      <div className={style.tableWrap}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Payment Information</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Payment ID</td>
              <td>{paymentInfoData?._id}</td>
            </tr>
            <tr>
              <td>Obligor name</td>
              <td>{paymentInfoData?.obligor_name}</td>
            </tr>
            <tr>
              <td>Payment Date</td>
              <td>{moment(paymentInfoData?.date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{paymentInfoData?.amount}</td>
            </tr>
            <tr>
              <td>Currency</td>
              <td>{paymentInfoData?.currency}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{paymentInfoData?.status}</td>
            </tr>
            <tr>
              <td>Asset ID</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Obligor ID</td>
              <td>{paymentInfoData?.obligor_id}</td>
            </tr>
            <tr>
              <td>Participant ID</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Reserve Amount</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Assignment Type</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Calculation Date</td>
              <td>--</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ReviewView;
