import { useField } from "formik";
import { useState } from "react";
import Info from "../../icons/Info";
import InfoContainer from "../Info";

const MyTextArea = ({
  info = "",
  label,
  icon,
  InputHeight = "small",
  width = "w-30",
  style,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [placeholder, setPlaceholder] = useState(props.customholder);

  const handleFocus = () => {
    setPlaceholder(
      props.customholder.substring(0, 5) === "Enter"
        ? props.customholder.substring(5)
        : props.customholder,
    );
  };
  console.log("Info :", info);

  const handleBlur = (event) => {
    field.onBlur(event);
    if (event.target.value === "") {
      setPlaceholder(props.customholder);
    }
  };

  return (
    <div className={width} style={style}>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div className={`d-flex align-center input-container input_icon_wrap`}>
        <textarea
          {...field}
          {...props}
          className={`${meta.touched && meta.error ? "error-input " : ""}${
            InputHeight === "small" ? "primary-input-small" : "primary-input"
          } `}
          placeholder=""
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <label
          class={`${
            InputHeight === "small"
              ? "input-placeholder-small"
              : "input-placeholder"
          }`}
        >
          {placeholder}
        </label>
      </div>
      {info && !meta.error && <InfoContainer info={info} />}
      {meta.touched && meta.error ? (
        <div
          className="error-input-message"
          style={{ marginTop: InputHeight === "small" ? "4px" : "8px" }}
        >
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default MyTextArea;
