import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../services/downloadService";

const PriceManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const [priceData, setPriceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GetPriceData = async () => {
    try {
      const res = await getData({
        endpoint: "/price",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          rate_type: filters?.["Rate Type"],
        },
      });
      setPriceData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetPriceData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.8,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.name}>
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2.5,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.description}>
            {params?.row?.description}
          </div>
        );
      },
    },
    {
      field: "rate_type",
      headerName: "Rate Type",
      flex: 0.65,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.rate_type}>
            {params?.row?.rate_type}
          </div>
        );
      },
    },
    {
      field: "top_rate",
      headerName: "Top Rate",
      flex: 0.65,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.top_rate}>
            {params?.row?.top_rate}
          </div>
        );
      },
    },
    {
      field: "base_rate",
      headerName: "Base Rate",
      flex: 1.1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.base_rate_name}>
            {params?.row?.base_rate_name}
          </div>
        );
      },
    },
    {
      field: "advance_rate",
      headerName: "Advance Rate",
      flex: 1.05,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.advance_rate}>
            {params?.row?.advance_rate}
          </div>
        );
      },
    },
    {
      field: "days",
      headerName: "Days",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.days}>
            {params?.row?.days}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params, index) => {
        return (
          // <div className={style.viewEdit}>
          //   <span
          //     type="button"
          //     className={style.viewStyle}
          //     onClick={() => {
          //       // handleView(params.row);
          //     }}
          //   >
          //     <View width="18" height="18" />
          //   </span>
          //   <span
          //     className={style.EditStyle}
          //     type="button"
          //     onClick={() => {
          //       handleEdit(params.row);
          //     }}
          //   >
          //     <EditBlue width="18" height="18" />
          //   </span>
          //   <span
          //     className={style.EditStyle}
          //     type="button"
          //     onClick={() => {
          //       setModalStateDelete(() => ({
          //         isModalOpen: true,
          //         modalTitle: 'Delete Broker',
          //         modalId: params?.row?._id,
          //       }));
          //     }}
          //   >
          //     <Delete width="18" height="18" />
          //   </span>
          // </div>
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No pricing created yet!"
          handleClick={() => {
            navigate("/dashboard/setting/price/add");
          }}
          tag="Add Pricing"
          subtitle="Currently no pricing created yet click on a add pricing button to create pricing"
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "price/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Price Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetPriceData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    "Rate Type": Rate_TypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "price/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Pricing</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />

          <CustomButton
            text="Export"
            handleClick={() => handleDownload()}
            classes={"filter-contain-btn"}
            iconRequired
            icon={<Upload />}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          <CustomButton
            text="Add Price"
            type="btn-primary"
            handleClick={() => {
              navigate("/dashboard/setting/price/add");
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={priceData?.[0]?.priceData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {priceData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={priceData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Pricing"
      />
    </>
  );
};
export default PriceManagement;
