import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { Heading } from "../../../../atoms/Heading";
import Basic from "./Basic";
import { useLocation, useParams } from "react-router-dom";
import { isDraft } from "@reduxjs/toolkit";

const Add = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Client");
  const { id, isDraft } = useParams();

  useEffect(() => {
    if (location?.state) {
      setActiveTab(location?.state?.edit);
    }
  }, [location?.state]);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Client",
        view: <Basic type="Client" />,
      },
      {
        id: 2,
        label: "Supplier",
        view: <Basic type="Supplier" />,
      },
      {
        id: 3,
        label: "Obligor",
        view: <Basic type="Obligor" />,
      },
      {
        id: 4,
        label: "Pre-Client",
        view: <Basic type="Pre-Client" />,
      },
    ],
    [],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      <Breadcrumb
        labels={[
          "Counterparties",
          `${
            id
              ? isDraft
                ? "Edit Draft Counterparty"
                : "Edit Counterparty"
              : "Add Counterparty"
          }`,
        ]}
        stepsToRemove={id && isDraft ? 2 : 1}
      />
      <Heading className="addHeaderStyle">Add Counterparty </Heading>

      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default Add;
