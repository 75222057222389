import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import * as Yup from "yup";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import MyTextArea from "../../../../../atoms/MyTextArea";
import { postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { clientNameData } from "../../../../../../utils/staticData";

const AddExpense = ({ selectedTermSheet, selectedDocuments }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();

  const initialValues = {
    internal_preparer: "",
    payee_entity: "",
    payee_invoice_reference: "",
    payee_invoice_amount: "",
    hch_allocation: "",
    hco_allocation: "",
    htf_allocation: "",
    Gtf_allocation: "",
    stf_allocation: "",
    Ptf_allocation: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    internal_preparer: Yup.string().required("Internal Preparer is required"),
    payee_entity: Yup.string().required("Payee Entity is required"),
    payee_invoice_reference: Yup.string().required(
      "Payee Invoice Reference entity is required",
    ),
    payee_invoice_amount: Yup.string().required(
      "Payee Invoice Amount is required",
    ),
  });
  const handleSubmit = async (values) => {
    console.log("values", values);
    let payload = {};
    if (selectedDocuments === "Expense") {
      switch (selectedTermSheet) {
        case "Expense Approval Form":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              htf_allocation: values?.htf_allocation,
              Gtf_allocation: values?.Gtf_allocation,
              stf_allocation: values?.stf_allocation,
              Ptf_allocation: values?.Ptf_allocation,
              internal_preparer: values?.internal_preparer,
              payee_entity: values?.payee_entity,
              payee_invoice_amount: values?.payee_invoice_amount,
              payee_invoice_reference: values?.payee_invoice_reference,
              notes: values?.notes,
            },
          };
          break;
        case "Expense Approval Form (All Funds)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              htf_allocation: values?.htf_allocation,
              Gtf_allocation: values?.Gtf_allocation,
              stf_allocation: values?.stf_allocation,
              Ptf_allocation: values?.Ptf_allocation,
              hch_allocation: values?.hch_allocation,
              hco_allocation: values?.hco_allocation,
              internal_preparer: values?.internal_preparer,
              notes: values?.notes,
              payee_entity: values?.payee_entity,
              payee_invoice_amount: values?.payee_invoice_amount,
              payee_invoice_reference: values?.payee_invoice_reference,
            },
          };
          break;
        default:
          break;
      }
    }
    try {
      const res = await postData({
        endpoint: "documents/create/documents",
        token: cookies.t,
        data: payload,
      });
      if (res) {
        toast.success("Documents Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/tools");
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="mt-4">
                <Heading className="HeadingStyle">Signer Selection</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyDropdown
                    placeholder="Internal Preparer"
                    data={clientNameData}
                    onChange={(option) => {
                      setFieldValue("internal_preparer", option);
                    }}
                    touched={touched.internal_preparer}
                    selectedDate={values.internal_preparer}
                    errors={errors.internal_preparer}
                    handleBlur={(e) => {
                      setFieldTouched("internal_preparer", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="payee_entity"
                    customholder="Payee Entity"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="payee_invoice_reference"
                    customholder="Payee Invoice Reference"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="payee_invoice_amount"
                    customholder="Payee Invoice Amount"
                    width="32.426%"
                  />
                </div>
              </div>
              <div className="mt-4">
                <Heading className="HeadingStyle">Variables</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  {selectedTermSheet ===
                    "Expense Approval Form (All Funds)" && (
                    <MyInput
                      type="text"
                      name="hch_allocation"
                      customholder="HCH Allocation (NAV Based:0)"
                      width="32.426%"
                    />
                  )}
                  {selectedTermSheet ===
                    "Expense Approval Form (All Funds)" && (
                    <MyInput
                      type="text"
                      name="hco_allocation"
                      customholder="HCO Allocation (NAV Based:0)"
                      width="32.426%"
                    />
                  )}
                  <MyInput
                    type="text"
                    name="htf_allocation"
                    customholder="HTF Allocation (NAV Based:0)"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="Gtf_allocation"
                    customholder="GTF Allocation (NAV Based:0)"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="stf_allocation"
                    customholder="STF Allocation (NAV Based:0)"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="Ptf_allocation"
                    customholder="PTF Allocation (NAV Based:0)"
                    width="32.426%"
                  />
                  <MyTextArea
                    as="textarea"
                    customholder="Other Notes and Adjustmentss"
                    name="notes"
                    info="Intentionally Left Blank or Term ABC adjusted to...."
                  />
                </div>
              </div>
              <div className={style.buttonWrap}>
                <button
                  className="btn btn-primary w-30 mt-2 btn-xl"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Generate Document
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AddExpense;
