import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import {
  documentInternalEnitity,
  NDADocumentTerms,
  SignerStaticData,
} from "../../../../../../utils/staticData";
import { getData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { emailRegex } from "../../../../../../helpers/expirationTime";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useNavigate } from "react-router-dom";

const AddNDA = ({ selectedDocuments, selectedTermSheet }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [signerData, setSignerData] = useState([]);
  const initialValues = {
    internal_signer: "",
    internal_signer_name: "",
    internal_entity: "",
    external_entity: "",
    ex_signer_first_name: "",
    ex_signer_last_name: "",
    ex_signer_email: "",
    terms: "",
  };
  const validationSchema = Yup.object().shape({
    internal_signer: Yup.string().required("Internal signer is required"),
    internal_entity: Yup.string().required("Internal entity is required"),
    external_entity: Yup.string().required("External entity is required"),
    ex_signer_first_name: Yup.string().required("First name is required"),
    ex_signer_last_name: Yup.string().required("Last name is required"),
    ex_signer_email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    terms: Yup.string().required("Terms is required"),
  });

  const getSignerList = async () => {
    try {
      const res = await getData({
        endpoint: "signer/getSignersList",
        token: cookies.t,
      });
      setSignerData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getSignerList();
  }, []);
  const handleSubmit = async (values) => {
    const payload = {
      documentType: selectedDocuments,
      documentSubType: selectedTermSheet,
      status: "Uploaded",
      DocumentsDetails: {
        internal_signer_name: values.internal_signer_name,
        internal_signer: values.internal_signer,
        internal_entity: values.internal_entity,
        external_entity: values.external_entity,
        external_entity_first_name: values.ex_signer_first_name,
        external_entity_last_name: values.ex_signer_last_name,
        external_entity_email: values.ex_signer_email,
        terms: values?.terms,
      },
    };
    try {
      const res = await postData({
        endpoint: "documents/create/documents",
        token: cookies.t,
        data: payload,
      });
      if (res) {
        toast.success("Documents Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/tools");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="mt-4">
                <Heading className="HeadingStyle">Signer Selection</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyDropdown
                    placeholder="Internal Signer"
                    data={SignerStaticData}
                    onChange={(option) => {
                      setFieldValue("internal_signer", option?.value);
                      setFieldValue("internal_signer_name", option.label);
                    }}
                    touched={touched.internal_signer}
                    selectedDate={values.internal_signer}
                    errors={errors.internal_signer}
                    handleBlur={(e) => {
                      setFieldTouched("internal_signer", true);
                      handleBlur(e);
                    }}
                    format="all"
                    width="32.426%"
                  />
                  <MyDropdown
                    placeholder="Internal Entity"
                    data={documentInternalEnitity}
                    onChange={(option) => {
                      setFieldValue("internal_entity", option);
                    }}
                    touched={touched.internal_entity}
                    selectedDate={values.internal_entity}
                    errors={errors.internal_entity}
                    handleBlur={(e) => {
                      setFieldTouched("internal_entity", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="external_entity"
                    customholder="External Entity"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_first_name"
                    customholder="External Signer First Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_last_name"
                    customholder="External Signer Last Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_email"
                    customholder="External Signer Email"
                    width="32.426%"
                  />
                </div>
              </div>
              <div className="mt-4">
                <Heading className="HeadingStyle">Document Variables</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyDropdown
                    placeholder="Select Terms"
                    data={NDADocumentTerms}
                    onChange={(option) => {
                      setFieldValue("terms", option);
                    }}
                    touched={touched.terms}
                    selectedDate={values.terms}
                    errors={errors.terms}
                    handleBlur={(e) => {
                      setFieldTouched("terms", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                  />
                </div>
              </div>
              <div className={style.buttonWrap}>
                <button
                  className="btn btn-primary w-30 mt-2 btn-xl"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Generate Document
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AddNDA;
