import { Formik } from "formik";
import InputLayout from "../../../../atoms/InputLayout";
import TextInput from "../../../../atoms/TextInput";
import { CurrencyData } from "../../../../../utils/staticData";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../../../atoms/Heading";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import * as Yup from "yup";
import MyDropdown from "../../../../atoms/MyDropdown";
import { useState } from "react";
import { Obj2FormData } from "../../../../../helpers/capatalizeLetters";
import Close from "../../../../icons/Close";
import MyInput from "../../../../atoms/MyInput";
import Modal from "../../../../atoms/modals/Modal";

const Obligor = ({ supplierObligorData, clientDetails }) => {
  const [cookie] = useCookies(["t"]);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState([]);
  const [modalStateImage, setModalStateImage] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    data: "",
  });
  const handleCloseModal = () => {
    setModalStateImage(() => ({
      isModalOpen: false,
      data: "",
    }));
  };

  const handleRemoveImage = (imageToRemove) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove),
    );
  };
  const handleMultipleImages = (file) => {
    setSelectedImage((prev) => [...prev, file]);
  };
  const initialValues = {
    obligor_name: "",
    obligor_id: "",
    invoice_face_value: "",
    max_advance: "",
    requested_advance_amount: "",
    currency: "",
    invoice_number: "",
    invoice_date: "",
    due_date: "",
    obligor_supporting_document: "",
  };
  const validationSchema = Yup.object().shape({
    obligor_id: Yup.string().required("Obligor name is required"),
    invoice_face_value: Yup.string().required("Invoice face value is required"),
    max_advance: Yup.string().required("Max advance is required"),
    requested_advance_amount: Yup.string().required(
      "Requted advance amount is required",
    ),
    currency: Yup.string().required("Currency is required"),
    invoice_number: Yup.string().required("Invoice number is required"),
    invoice_date: Yup.string().required("Invoice date is required"),
    due_date: Yup.string().required("Due date is required"),
    obligor_supporting_document: Yup.array().test(
      "images-length",
      "Supporting documents is required",
      function (value) {
        // Custom test to check if images length is greater than 0
        return selectedImage.length > 0;
      },
    ),
  });
  const handleSubmit = async (values) => {
    const formData = new FormData();
    selectedImage?.map((item) => formData.append("files", item, item?.name));
    try {
      const dataToSend = {
        client_id: clientDetails?.clientId,
        client_name: clientDetails?.clientName,
        obligor_name: values?.obligor_name,
        obligor_id: values?.obligor_id,
        face_value: values?.invoice_face_value,
        max_advance: values?.max_advance,
        req_advance_value: values?.requested_advance_amount,
        currency: values?.currency,
        invoice_number: values?.invoice_number,
        invoice_date: values?.invoice_date,
        due_date: values?.due_date,
        status: "Draft",
        transaction_type: "Obligor",
        files: formData,
      };
      const res = await postFormData({
        endpoint: "funding/createFunding",
        token: cookie.t,
        data: Obj2FormData(dataToSend, formData),
      });
      if (res) {
        toast.success("Funding Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      navigate("/dashboard/funding");
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={123456}
        onSubmit={handleSubmit}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Heading className="addHeaderStyle mt-4">Funding Details</Heading>
            <div
              className="d-flex flex-wrap w-100 "
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                data={supplierObligorData}
                placeholder="Obligor Name"
                onChange={(option) => {
                  setFieldValue("obligor_name", option.label);
                  setFieldValue("obligor_id", option.value);
                }}
                format="all"
                touched={touched.obligor_id}
                errors={errors.obligor_id}
                handleBlur={(e) => {
                  setFieldTouched("obligor_id", true);
                  handleBlur(e);
                }}
                width="23.9%"
              />
              <MyInput
                type="text"
                name="invoice_face_value"
                customholder="Invoice Face Value"
                onBlur={(e) => {
                  // handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <MyInput
                type="text"
                name="max_advance"
                customholder="Max Advance"
                onBlur={(e) => {
                  // handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <MyInput
                type="text"
                name="requested_advance_amount"
                customholder="Requested Advance Amount"
                onBlur={(e) => {
                  // handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <MyDropdown
                data={CurrencyData}
                placeholder="Currency"
                onChange={(option) => {
                  setFieldValue("currency", option);
                }}
                touched={touched.currency}
                errors={errors.currency}
                handleBlur={(e) => {
                  setFieldTouched("currency", true);
                  handleBlur(e);
                }}
                width="23.9%"
              />
              <MyInput
                type="text"
                name="invoice_number"
                customholder="Invoice Number/Reference"
                onBlur={(e) => {
                  // handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Invoice Date"
                  onChange={(option) => {
                    setFieldValue("invoice_date", option);
                  }}
                  touched={touched.invoice_date}
                  selectedDate={values.invoice_date}
                  errors={errors.invoice_date}
                  handleBlur={(e) => {
                    setFieldTouched("invoice_date", true);
                    handleBlur(e);
                  }}
                />
              </div>
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Due Date"
                  onChange={(option) => {
                    setFieldValue("due_date", option);
                  }}
                  touched={touched.due_date}
                  errors={errors.due_date}
                  selectedDate={values.due_date}
                  disablePast={true}
                  // shouldDisableDate={disablePastDays}
                  handleBlur={(e) => {
                    setFieldTouched("due_date", true);
                    handleBlur(e);
                  }}
                />
              </div>
              <div className="col-3">
                <ImageSelector
                  placeholder="Upload Supporting Documents"
                  handleFile={(file) => {
                    handleMultipleImages(file);
                    // setFieldValue("obligor_supporting_document", file);
                  }}
                  multiple
                  touched={touched.obligor_supporting_document}
                  errors={errors.obligor_supporting_document}
                  // customStyles={{ width: "23.9%" }}
                />
              </div>
              {selectedImage?.map((item) => {
                return (
                  <div className={`col-3`}>
                    <div className={style.imagesNameStyle}>
                      <span
                        className={style.imagesName}
                        onClick={() => {
                          if (
                            item?.type === "application/pdf" ||
                            item?.type === "image/jpeg"
                          ) {
                            setModalStateImage(() => ({
                              data: URL.createObjectURL(item),
                              isModalOpen: true,
                            }));
                          } else {
                            //docs and excel not supported on local
                            setModalStateImage(() => ({
                              data: `https://docs.google.com/gview?url=${encodeURIComponent(
                                item?.name,
                              )}&embedded=true`,
                              isModalOpen: true,
                            }));
                          }
                        }}
                      >
                        {item?.name}
                      </span>
                      <div
                        className={style.closeIcon}
                        onClick={() => {
                          handleRemoveImage(item);
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                />
                <CustomButton
                  text={"Add"}
                  type="btn-primary"
                  size="btn-lg"
                  disabled={isSubmitting}
                  handleClick={() => {
                    handleSubmit(values);
                  }}
                />
              </div>
            </div>
            <Modal
              isActive={modalStateImage.isModalOpen}
              onClose={handleCloseModal}
              width="700px"
              bodyStyles={{ height: "700px" }}
            >
              <iframe
                width="100%"
                height="100%"
                src={modalStateImage.data}
                title="hello"
                // sandbox="allow-scripts allow-same-origin"
              />
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};
export default Obligor;
